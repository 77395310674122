import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px; 
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function AccessControlPage() {
  return (
    <Layout pageName="technology">
      <SEO
        title="Access Control Technology and Systems"
        description="WYN Technologies have state-of-the-art access control technology to provide the best security and monitoring for the businesses of all sizes and capacity. "/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Access Control Solutions</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Access Control Solutions</GroceryTitle>

              <p>While your business may have an open-door policy for customers and clients, that doesn’t mean you can just leave the front door wide-open all the time. The security of your people and your facility is simply too important to leave to chance!</p>

              <p>Looking for the best solution? Allow <strong>WYN Technologies</strong> to install a state-of-the-art access control system for you! </p>

              <p>The right access control solution allows you to have more control over who can enter your building and when. You can also choose from a variety of keyless door access options, including PINs, fobs, mobile devices, and biometrics. We offer multiple hardware/software configurations and options too.</p>

            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">What We Offer With Our Access Control Services</GroceryIndustryTitle>

          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Sales, Installation, & Product Orientation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p><strong>WYN</strong> is proud to be a preferred provider of quality access control products from makers like Schlage, Dorma Kaba, Valcom, and more. But we’re more than just an access hardware supply vendor; we’re happy to supply complete access control system installation services, too! We install everything from keyless door lock hardware, to PIN pads, to fob readers, to biometrics-recognizing devices. And we won’t just install your access control equipment and then leave you guessing as to how it works, either. After installation, we’ll provide proper product orientation, and guide you through the entire product operation process. We’ll also take the time to answer any and all questions you may have.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Site Surveys</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Before we ever recommend an access control business technology solution, we’ll start by actually having a conversation with you. When you first reach out to us, we’ll take the time to learn more about your business, your facility, and your goals for access control. We’ll then have one or more of our expert technicians come out and actually do a physical, in-person survey of your site. Next, we’ll sit down with you to discuss our findings, and then we’ll share our recommendations for the best access control technological solution to meet your particular needs and budget.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Competitive Pricing & Outstanding Workmanship</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>If you’re like many businesses and organizations, your approved budget for implementing access control equipment solutions is likely to be a fixed number. We get that, and we have lots of experience working within the parameters and constraints of your operating budget. We’ll work hard to help you find the best-quality access control equipment solution that also fits within your allocated finances. And once we land upon the perfect solution for your business, it’s time to bring in our team of expert access control installers. <strong>WYN</strong> has come to be known for the high standard of workmanship our technicians deliver for our satisfied clients every single day!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Ongoing Support</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Another distinctive fact about <strong>WYN</strong> is that we don’t just leave you high and dry after your technology solution is installed and implemented. We also offer some beneficial “service after the sale” contract options to provide even greater peace of mind. Let us worry about keeping your access control system working properly, so you won’t have to!</p>
                <p>We’ll be here whenever you need us. Experiencing a problem or issue with your access control hardware or connected devices? We can help with that, and we’re available to you 24/7. We’re serious about providing ongoing support services you can trust.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>

            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                {/* <h2 className="h1">Access Control Basics</h2> */}
              </MarkdownContentTitle>
              <MarkdownContent>
                <h2>Access Control Basics</h2>
                <p>Access control has grown and evolved quite a bit over the years. The first iteration of access control was all about keeping people out, and included physical barriers like walls, moats, and fences. Next came operable gates and mechanical locks. With the advent of modern business, it was no longer about keeping everyone out, but rather keeping the wrong people out. Physical access control was facilitated by paid guards, bouncers, and bruisers.</p>

                <p>But thanks to modern technological advances, you no longer need to post a sentry at every door. Access control devices have digitized and automated the entire process. Plus, access control entails more than just physical entrances. Access control technology adds security to your wired and wireless networks, too.
                </p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Which Type of Access Control Security Do You Need?</h2>
                <p>That really depends upon you, your particular facility, and your organizational needs & goals for a door access system and other access control technology. The access control systems that <strong>WYN</strong> installs do much more than simply provide gate access control. They also help to improve operational efficiencies, extend your range of capabilities, and offer you greater peace of mind knowing that your people, your assets, and your resources are secure and protected.</p>

                <p>Looking for some good keyless entry options? How about an incredibly secure biometric access control system? And what about adding some important layers of security to your digital business network? <strong>WYN</strong> can get you set up with anything and everything you may need!</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>An Overview of Some Essential Access Control Technologies</h2>
                <p>LThe best access control systems incorporate many modern technologies which provide for both the physical and digital security of your business or organization. Here are a few of the most important technologies included in today’s access control systems:</p>
                <ul>
                  <li>
                    <strong>Physical Access Control System (PACS)</strong>
                    <p>This form of direct physical access control is based on the use of a personal information card verification (PIV) card with a connected card reader. This type of security system usually involves a two-step authentication process, and is commonly used in many government, military, and other high-security areas. Technology of this nature can also be utilized by business enterprises, as well.</p>
                  </li>
                  <li>
                    <strong>Radio Frequency Identification (RFID)</strong>
                    <p>In this popular access control technology, an individual’s identifying credentials are stored on a tag, keychain fob, or similar such item. Whenever the tag or fob is waved within close proximity to the reader device, electromagnetic waves are generated by the reader in order to capture the information stored on the tag or fob. The visitor is then automatically granted (or denied) access.</p>
                  </li>
                  <li>
                    <strong>Near Field Communication (NFC)</strong>
                    <p>Two near field communication devices are able to establish a communication medium through an air interface by means of an electromagnetic induction field created between the two whenever they’re brought within close proximity. Access control devices of this nature typically operate at 13.56 MHz frequency, and optimal range for connection and communication is somewhere around 1.5 inches.</p>
                  </li>
                  <li>
                    <strong>Bluetooth Access Control</strong>
                    <p>Bluetooth is a very commonly-used technology, and access control can be set up to accommodate any authorized Bluetooth-enabled devices. Data is transferred between these devices through what’s known as a frequency-hopping spectrum spread radio medium, which operates within the ISM band of radio frequency. Bluetooth range can extend up to 800 feet in distance, and can also allow for some pretty robust data transfer rates.</p>
                  </li>
                  <li>
                    <strong>Mobile Access Control</strong>
                    <p>Key fobs and tags aren’t the only way to gain access through an RFID system. Today’s mobile phones and smart devices can be converted into electronic key fobs themselves through the simple installation of a dedicated access control system app. In addition, an authorized user can even use their smartphone to manage and control any part of your access control system itself.</p>
                  </li>
                  <li>
                    <strong>Cloud-Based Access Control</strong>
                    <p>Many businesses and organizations have moved their networks to the cloud, and you can set up your access control system to be cloud-based, as well. There are many benefits that come along with a cloud-based approach, including operational improvements, improved security, and easier multi-site facility management. These types of access control systems are easily scalable to meet the growing needs of your business, too.</p>
                  </li>
                  <li>
                    <strong>Biometric Access Control</strong>
                    <p>Biometrics have been around for decades, but were somewhat limited in adoption due to challenges of accuracy and affordability. Thanks to continued technological advances, however, biometric access control applications have become truly viable for businesses everywhere. In addition to fingerprint readers, there are now all sorts of biometric reader options, including face, palm, iris, voice, and more.</p>
                  </li>
                  <li>
                    <strong>Touchless Access Control Systems</strong>
                    <p>Touchless access control systems were already well-liked for their convenience and ease of use, but thanks to the recent pandemic these items are now in greater demand than ever before. Face recognition and iris matching are two key emerging biometric technologies in this regard. Other touchless systems which utilize things like RFID fobs, tags, Bluetooth devices, and smartphone apps also remain popular.</p>
                  </li>
                  <li>
                    <strong>Wired Access Control</strong>
                    <p>Wired access control is enabled by the installation of a sound business network infrastructure. This includes things like CAT6 cable communication, structured cabling, PoE access, and other traditional wiring solutions. Connected wired devices and components are able to connect and transfer data back and forth through a variety of different communication protocols.</p>
                  </li>
                  <li>
                    <strong>Power over Ethernet (PoE)</strong>
                    <p>Structured cabling or low-voltage cable technology makes use of cables which carry both data signals and the energy needed to power various access control components, including IP cameras, access controllers, business telephones, routers, access points, and other elements. Another advantage to this approach is that it’s one of most cost-effective ways to manage access control and other business applications.</p>
                  </li>
                  <li>
                    <strong>Wireless Access Control</strong>
                    <p>Wireless access control is a general category which includes any and all wireless technologies which may be used for the purposes of managing, monitoring, and engaging your access control system. Among other things, this includes technologies like Bluetooth, NFC, RFID, mobile, WiFi, and more.</p>
                  </li>
                  <li>
                    <strong>Internet of Things (IoT)</strong>
                    <p>The Internet of Things (or IoT) describes the network of physical objects that are embedded with sensors, software, and other technologies for the purpose of connecting and exchanging data with other devices and systems over the Internet. As it pertains to access control systems, this involves IP-based communication between all components of your access control technology which are IP-enabled.</p>
                  </li>

                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h2>What to Look for in an Access Control System</h2>
                <p>So, which type of access control system is right for you? That will depend upon various factors, including the size of your organization, the physical layout of your facility, what features you’re looking to include, and your budget. Even so, here are some desirable features that can be found in a good access control system:</p>
                <ul>
                  <li>Openly compatible with third-party hardware</li>
                  <li>Provides for both physical and digital security</li>
                  <li>Meets all federal, state, and local regulations</li>
                  <li>Can be integrated with video surveillance and other related systems</li>
                  <li>Supports modern wired and wireless technologies, including both mobile and cloud-based access</li>
                  <li>Supports multiple access authorization options like keypads, fobs, mobile apps, biometrics, and two-factor authentication</li>
                  <li>Simple to configure, and easy to use</li>
                  <li>Backed by reliable, easily accessible customer support</li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Trust WYN to Deliver the Access Control System You Need!</h2>
                <p>No matter what type of access control system you’re looking for – or even if you’re not quite sure what you even need – <strong>WYN Technologies</strong> is here to help. In fact, that’s what <strong>WYN</strong> stands for: <strong>Whatever You Need</strong>! No matter what your project may entail, we’ll take care of it, and we’ll take care of you the right way, too! </p>

                <p>With over 75 years of customer service experience under our collective belts, we’ve learned a few things about the importance of following ethical principles and keeping clear channels of communication open with our clients. Our goal is to always be as transparent & clear with you as possible, and we’ll keep you well-informed every step of the way.</p>
                <p>
                Got questions? Ready to get started? Give us a call at <a href="tel:3368990555">(336) 899-0555</a> today, and let <strong>WYN</strong> help you take full control of your access control!
                </p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default AccessControlPage
